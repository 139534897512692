import React from "react";

const Socials = () => {
	return (
		<>
			<a
				href="https://www.linkedin.com/in/ole-martin-kirsebom-a801a2238/"
				target="_blank"
			>
				<i class="fab fa-linkedin"></i>
			</a>
			<a href="https://www.instagram.com/omkirsebom/" target="_blank">
				<i class="fab fa-instagram"></i>
			</a>
			<a href="https://www.facebook.com/olemartin.kirsebom" target="_blank">
				<i class="fab fa-facebook-square"></i>
			</a>
			<div className="socials-filler-bar"></div>
		</>
	);
};

export default Socials;
