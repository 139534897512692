import React from "react";

const Modal = (props) => {
	return (
		<div className="modal">
			<p>{props.text}</p>
			<button onClick={props.onCancel}>Lukk Beskjed</button>
		</div>
	);
};

export default Modal;
