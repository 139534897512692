import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup
	.object()
	.shape({
		email: yup
			.string()
			.required("Skriv inn din email")
			.email("Skriv en gyldig email"),
		message: yup
			.string()
			.required("Skriv inn din melding til Ole-Martin")
			.min(30, "Melding må bestå av minst 30 karakterer"),
	})
	.required();

const ContactForm = (props) => {
	const form = useRef();
	const [loading, setLoading] = useState(false);

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});
	function onSubmit(data) {
		setLoading(true);
		console.log(data);
		emailjs
			.sendForm(
				"service_opvagbv",
				"template_w3q1d2j",
				form.current,
				"user_5Kri94Dvt1CBgIIUFQLZe"
			)
			.then(
				(result) => {
					console.log(result.text);
					if (result.text === "OK") {
						setLoading(false);
						props.getModalText("Din melding har blitt sendt");
						props.setModalIsOpen(true);
						reset();
					}
				},
				(error) => {
					console.log(error.text);
					if (error) {
						setLoading(false);
						props.getModalText(
							"Ups, dette var pinlig.. Din melding ble ikke sendt..Vennligst prøv igjen"
						);
						props.setModalIsOpen(true);
					}
				}
			);
	}

	return (
		<form
			ref={form}
			className="contact-section-form"
			onSubmit={handleSubmit(onSubmit)}
		>
			<div className="input-container">
				<input name="email" className="input" {...register("email")} />
				<label for="email" className="label">
					Email
				</label>
			</div>
			{errors.email && (
				<span className="error-message">{errors.email.message}</span>
			)}

			<div className="textarea-container">
				<textarea
					name="message"
					className="textarea"
					{...register("message")}
				/>
				<label for="message" className="textarea-label">
					Din melding
				</label>
			</div>
			{errors.message && (
				<span className="error-message">{errors.message.message}</span>
			)}

			{loading ? (
				<div className="spin"></div>
			) : (
				<div className="form-button-container">
					<button className="form-button">Send Melding</button>
					<div className="form-button-background"></div>
				</div>
			)}
		</form>
	);
};

export default ContactForm;
