import Title from "../parts/Title";
import { useEffect, useState, useRef } from "react";
import ArrowButton from "../parts/ArrowButton";

const AboutSection = (props) => {
	const AboutRef = useRef(null);

	useEffect(
		function () {
			props.onGetRef(AboutRef.current.offsetTop, AboutRef.current.offsetHeight);

			function handleResize() {
				props.onGetRef(
					AboutRef.current.offsetTop,
					AboutRef.current.offsetHeight
				);
			}
			handleResize();
			window.addEventListener("resize", handleResize);
		},
		[AboutRef, props]
	);

	function handleClick() {
		window.scrollTo({
			top: props.workOffsetTop - 80,
		});
	}
	function handleContactClick() {
		const documentHeight = document.documentElement.scrollHeight;
		window.scrollTo({
			top: documentHeight - props.contactOffsetHeight - 80,
		});
	}

	// En Modal som popper opp når brukeren har bladd ned til aboutRef. Der står det: Det ekte svaret finnes vel på sosiale medier
	//Iconer som linker til sosiale medier
	//Tekst noe alla: Men hyggelig om du blar videre på nettsiden min :)
	return (
		<section className="about_section" ref={AboutRef}>
			<div className="about-section-content">
				<Title title="Hvem er jeg?" />
				<p className="about-me-text">
					Hei, jeg heter Ole-Martin og jeg er en front-end utvikler som akkurat
					har fullført studiet front-end utvikling på Noroff. Jeg tar stolthet i
					å bygge og utvikle estetisk fine og moderne nettsider med fokus på god
					UX-design. Front-end utvikling har ikke bare vært ett studie for meg,
					men det har også blitt en hobby. Jeg er nysgjerrig og alltid på jakt
					etter å utvikle meg innenfor webutvikling. Jeg synes det er spennende
					å jobbe med API`er, men akkurat nå er jeg oppslukt av rammeverket
					React og bruker mye av fritiden min på ett React kurs på udemy. Er du
					interessert i å finne ut mer om meg ta gjerne{" "}
					<span className="about-me-kontakt-link" onClick={handleContactClick}>
						kontakt.
					</span>
				</p>
			</div>
			<ArrowButton className="Arrow_btn" onClick={handleClick} />
		</section>
	);
};

export default AboutSection;
