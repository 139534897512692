import React from "react";

const FooterNav = (props) => {
	function clickHome() {
		window.scrollTo({
			top: 0,
		});
	}

	function clickAboutme() {
		window.scrollTo({
			top: props.aboutOffsetTop - 95,
		});
	}

	function clickWork() {
		window.scrollTo({
			top: props.workOffsetTop - 80,
		});
	}

	return (
		<nav className="footer-nav">
			<a className="footer-nav-link" onClick={clickHome}>
				Hjem
			</a>
			<a className="footer-nav-link" onClick={clickAboutme}>
				Om meg
			</a>
			<a className="footer-nav-link" onClick={clickWork}>
				Prosjekter
			</a>
		</nav>
	);
};

export default FooterNav;
