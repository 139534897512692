import React from "react";
import { useRef, useEffect } from "react";
import Title from "../parts/Title";
import JobbklarImage from "../../images/jobbklar.png";
import VannverkImage from "../../images/krokvaagvannverk1.png";
import ArrowButton from "../parts/ArrowButton";
import github from "../../icons/github.svg";
import attest from "../../images/attest.pdf";
import holidaze from "../../images/holidaze.png";
import shoefront from "../../images/shoefront.png";
import pokemon from "../../images/pokemon.jpg";
import nextImage from "../../images/nextjs.png";
import musicImage from "../../images/musiclogo.png";

const WorkSection = (props) => {
	const workRef = useRef(null);

	useEffect(
		function () {
			console.log("worksection bigger");
			props.onGetRef(workRef.current.offsetTop, workRef.current.offsetTop);

			function handleResize() {
				props.onGetRef(workRef.current.offsetTop, workRef.current.offsetTop);
			}
			handleResize();
			window.addEventListener("resize", handleResize);
		},
		[workRef, props]
	);

	function handleClick() {
		const documentHeight = document.documentElement.scrollHeight;
		window.scrollTo({
			top: documentHeight - props.contactOffsetHeight - 80,
		});
	}
	function redirectKrokvag() {
		window.open("http://krokvagvannverk.no/", "_blank");
	}
	function redirectJobbklar() {
		window.open(attest, "_blank");
	}

	function redirectHolidaze() {
		window.open("http://olemartink.no/", "_blank");
	}

	function redirectHolidazeGithub() {
		window.open("https://github.com/kirsebom/holidaze", "_blank");
	}
	function redirectShoefront() {
		window.open("https://pokedex-8e1b4.web.app/", "_blank");
	}
	function redirectShoefrontGithub() {
		window.open("https://github.com/kirsebom/pokedex", "_blank");
	}
	function redirectEcommerceSite() {
		window.open("https://ecommerce-for-ole-martin-no.vercel.app/", "_blank");
	}
	function redirectEcommerceSiteGithub() {
		window.open(
			"https://github.com/kirsebom/ecommerce-for-ole-martin.no",
			"_blank"
		);
	}
	function redirectMusicPlayer() {
		window.open("https://music-player-tau-seven.vercel.app/", "_blank");
	}
	function redirectMusicPlayerGithub() {
		window.open("https://github.com/kirsebom/music-player", "_blank");
	}
	return (
		<section className="work_section" ref={workRef}>
			<div className="work-section-content">
				<Title title="Prosjekter" />
				<div className="cards-container">
					<div className="card shoefront-card">
						<img
							src={nextImage}
							alt="Holidaze logo"
							className="shoefront-image"
						/>
						<p>
							Laget en fiktiv nettbutikk som er integrert med egen backend
							server jeg satt opp i strapi, bruker stripe(test mode) som
							checkout og auth0 for innlogging/lage kundebrukere. Jeg har brukt
							react pakken framer motion til å legge til animasjoner på
							komponenter som handlevognen.
						</p>
						<div className="buttons-container-work">
							<div className="work-button-container">
								<button className="work-button" onClick={redirectEcommerceSite}>
									Nettside
								</button>
								<div className="work-button-background"></div>
							</div>
							<a onClick={redirectEcommerceSiteGithub}>
								<img src={github} alt="github icon" className="github-icon" />
							</a>
						</div>
					</div>
					<div className="card shoefront-card">
						<img
							src={pokemon}
							alt="Holidaze logo"
							className="shoefront-image"
						/>
						<p>
							Laget en pokedex hvor jeg bruker Redux som State Management.
							Henter data fra to forskjellige API`er samt bilder fra en
							selvstending url. Knytter så sammen all data for å få en fungerene
							pokedex.
						</p>
						<div className="buttons-container-work">
							<div className="work-button-container">
								<button className="work-button" onClick={redirectShoefront}>
									Nettside
								</button>
								<div className="work-button-background"></div>
							</div>
							<a onClick={redirectShoefrontGithub}>
								<img src={github} alt="github icon" className="github-icon" />
							</a>
						</div>
					</div>
					<div className="card holidaze-card">
						<img src={musicImage} alt="Music icon" className="holidaze-image" />
						<p>
							Poorify er en musikkspiller med en spilleliste. Sangene til
							spillelisten er hentet fra Chillhops som har opphavsrettsfri
							musikk. Laget for gøy uten noe state management system da jeg
							ville fokusere på å løfte opp "staten" og "prop-drilling".
						</p>
						<div className="buttons-container-work">
							<div className="work-button-container">
								<button className="work-button" onClick={redirectMusicPlayer}>
									Nettside
								</button>
								<div className="work-button-background"></div>
							</div>
							<a onClick={redirectMusicPlayerGithub}>
								<img src={github} alt="github icon" className="github-icon" />
							</a>
						</div>
					</div>
					<div className="card holidaze-card">
						<img
							src={holidaze}
							alt="Holidaze logo"
							className="holidaze-image"
						/>
						<p>
							Tidligere eksamens oppgave hvor jeg laget en API i wordpress som
							blir brukt til å vise innholdet på nettsiden. På nettsiden kan man
							logge seg inn med brukernavn kirsebom og passord pokemon1 slik at
							man kan legge til nye produkter i API`en.
						</p>
						<div className="buttons-container-work">
							<div className="work-button-container">
								<button className="work-button" onClick={redirectHolidaze}>
									Nettside
								</button>
								<div className="work-button-background"></div>
							</div>
							<a onClick={redirectHolidazeGithub}>
								<img src={github} alt="github icon" className="github-icon" />
							</a>
						</div>
					</div>

					<div className="card">
						<img src={JobbklarImage} alt="Jobbklar Logo" />
						<p>
							Konsulterte bedriften Jobbklar/Proflex ved å lage en prototype
							nettbutikk. Stillingen gikk også ut på å være en rådgiver og
							hjelpe bedriften i testfasen for å finne ut om de var i stand til
							å drive en nettbutikk for bruktbutikken deres.
						</p>
						<div className="work-button-container">
							<button className="work-button" onClick={redirectJobbklar}>
								Attest
							</button>
							<div className="work-button-background"></div>
						</div>
					</div>
					<div className="card krokvag-card">
						<img src={VannverkImage} alt="Krokvåg vannverk logo" />
						<p>
							Nettsiden til Krokvåg vannverk er den første nettsiden jeg har
							laget. Daglig leder av vannverket ville ha en enkel, informativ og
							brukervennlig nettside. Nettsiden kunne trengt en oppdatering, men
							daglig leder er fornøyd med hvordan nettsiden er per dags dato.
						</p>
						<div className="work-button-container">
							<button className="work-button" onClick={redirectKrokvag}>
								Nettside
							</button>
							<div className="work-button-background"></div>
						</div>
					</div>
				</div>
			</div>
			<ArrowButton className="Arrow_btn" onClick={handleClick} />
		</section>
	);
};

export default WorkSection;
