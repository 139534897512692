import React from "react";
import { useState, useEffect } from "react";
import Logo from "../../images/headerlogo.png";

const Navigation = (props) => {
	const [offset, setOffset] = useState(0);
	const [aboutActiveClass, setAboutActiveClass] = useState("");
	const [workActiveClass, setWorkActiveClass] = useState("");
	const [contactActiveClass, setContactActiveClass] = useState("");

	useEffect(
		function () {
			window.onscroll = () => {
				setOffset(window.pageYOffset);
			};
			if (
				offset > props.aboutOffsetTop * 0.7 - props.homeOffsetTop &&
				offset < props.workOffsetTop * 0.7
			) {
				setAboutActiveClass("active");
			} else {
				setAboutActiveClass("");
			}

			if (
				offset > props.workOffsetTop * 0.7 &&
				offset < props.contactOffsetTop * 0.9
			) {
				setWorkActiveClass("active");
			} else {
				setWorkActiveClass("");
			}

			if (offset > props.contactOffsetTop * 0.9) {
				setContactActiveClass("active-contact");
			} else {
				setContactActiveClass("");
			}
		},
		[
			offset,
			props.aboutOffsetTop,
			props.homeOffsetTop,
			props.workOffsetTop,
			props.contactOffsetTop,
			props,
		]
	);
	function clickLogoHandler() {
		window.scrollTo({
			top: 0,
		});
	}

	function clickAboutMeHandler() {
		window.scrollTo({
			top: props.aboutOffsetTop - 95,
		});
	}
	function clickWorkHandler() {
		window.scrollTo({
			top: props.workOffsetTop - 80,
		});
	}

	function clickContactHandler() {
		console.log(document.documentElement.scrollHeight);
		const documentHeight = document.documentElement.scrollHeight;
		window.scrollTo({
			top: documentHeight - props.contactOffsetHeight - 80,
		});
		// console.log(props.contactOffsetHeight);
		// if (props.contactOffsetHeight > 527) {
		// 	window.scrollTo({
		// 		top: props.contactOffsetTop - 80,
		// 	});
		// } else {
		// 	window.scrollTo({
		// 		top:
		// 			props.homeOffsetHeight +
		// 			props.aboutOffsetHeight +
		// 			props.workOffsetHeight -
		// 			80,
		// 	});
		// }
	}

	// console.log(props.aboutOffsetTop);
	// console.log(props.aboutOffsetHeight);

	return (
		<nav className="navigation">
			<div className="logo_container">
				<a onClick={clickLogoHandler}>
					<img className="logo" src={Logo} alt="Logo OMK" />
				</a>
			</div>
			<div className="nav-links_container">
				<a className={aboutActiveClass} onClick={clickAboutMeHandler}>
					Om Meg
				</a>
				<a className={workActiveClass} onClick={clickWorkHandler}>
					Prosjekter
				</a>
				<button className={contactActiveClass} onClick={clickContactHandler}>
					Ta Kontakt
				</button>
			</div>
		</nav>
	);
};

export default Navigation;
