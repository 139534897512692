import React from "react";
import { useRef, useEffect, useState } from "react";
import MyPicture from "../../images/mypicture.jpg";
import ArrowButton from "../parts/ArrowButton";
import RoundProfilePicture from "../../images/mypicture-modified.png";

const HomeSection = (props) => {
	// const [profilePicture, setProfilePicture] = useState(MyPicture);
	const homeRef = useRef(null);

	useEffect(
		function () {
			props.onGetRef(homeRef.current.offsetTop, homeRef.current.offsetHeight);

			function handleResize() {
				props.onGetRef(homeRef.current.offsetTop, homeRef.current.offsetHeight);
			}
			handleResize();
			window.addEventListener("resize", handleResize);
		},
		[homeRef, props]
	);

	function handleClick() {
		console.log(document.documentElement.scrollWidth);
		window.scrollTo({
			top: homeRef.current.offsetHeight,
		});
	}

	function handleButtonClick() {
		window.scrollTo({
			top: props.workOffsetTop - 80,
		});
	}

	// useEffect(function () {
	// 	window.addEventListener("resize", handleResize);

	// 	function handleResize() {
	// 		if (window.innerWidth < 699) {
	// 			setProfilePicture(RoundProfilePicture);
	// 		} else {
	// 			setProfilePicture(MyPicture);
	// 		}
	// 	}
	// 	handleResize();
	// });

	return (
		<section className="home_section" ref={homeRef}>
			<div className="home_content_container">
				<div className="home_content_text_container">
					<p>front-end utvikler</p>
					<h1>Ole-Martin Kirsebom</h1>
					<div className="button-container">
						<button className="download-btn" onClick={handleButtonClick}>
							Prosjekter
						</button>
						<div className="button-background"></div>
					</div>
				</div>

				<picture className="home_content_image_container">
					<source
						media="(max-width:699px)"
						srcSet={RoundProfilePicture}
						className="profile-picture"
					/>
					<img src={MyPicture} className="profile-picture" />
				</picture>

				{/* <div className="home_content_image_container">
					<img
						className="profile-picture"
						src={profilePicture}
						alt="Ole-Martin Kirsebom"
					/>
				</div> */}
			</div>

			<ArrowButton className="Arrow_btn" onClick={handleClick} />
		</section>
	);
};

export default HomeSection;
