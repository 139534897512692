import React from "react";
import { useRef, useEffect, useState } from "react";
import Title from "../parts/Title";
import Socials from "../parts/Socials";
import FooterNav from "../parts/FooterNav";
import ContactForm from "../parts/ContactForm";
import Modal from "../parts/Modal";
import Backdrop from "../parts/Backdrop";

const ContactSection = (props) => {
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [modalText, setModalText] = useState("");

	function closeModalHandler() {
		setModalIsOpen(false);
		console.log("Clicked");
	}

	const contactRef = useRef(null);

	useEffect(
		function () {
			console.log("CONTACT_SECTION");
			props.onGetRef(
				contactRef.current.offsetTop,
				contactRef.current.offsetHeight
			);

			function handleResize() {
				props.onGetRef(
					contactRef.current.offsetTop,
					contactRef.current.offsetHeight
				);
			}
			handleResize();
			window.addEventListener("resize", handleResize);
		},
		[contactRef, props]
	);

	function getModalText(text) {
		console.log(text);
		setModalText(text);
	}

	return (
		<section className="contact_section" ref={contactRef}>
			<div className="contact-section-content">
				<Title title="Kontakt meg" />
				<div className="contact-section-content-container">
					<div className="contact-section-text">
						<p>Olemartinkirsebom@gmail.com</p>
						<div className="socials">
							<Socials />
						</div>

						<FooterNav
							workOffsetTop={props.workOffsetTop}
							aboutOffsetTop={props.aboutOffsetTop}
						/>
					</div>
					<ContactForm
						setModalIsOpen={setModalIsOpen}
						getModalText={getModalText}
					/>
				</div>
			</div>
			{modalIsOpen && <Modal text={modalText} onCancel={closeModalHandler} />}
			{modalIsOpen && <Backdrop onCancel={closeModalHandler} />}
		</section>
	);
};

export default ContactSection;
