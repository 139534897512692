import "./sass/style.scss";
import HomeSection from "./components/sections/HomeSection";
import AboutSection from "./components/sections/AboutSection";
import WorkSection from "./components/sections/WorkSection";
import ContactSection from "./components/sections/ContactSection";
import Navigation from "./components/parts/Navigation";
import SeperationBar from "./components/parts/SeperationBar";
import { useState } from "react";
function App() {
	const [homeOffsetTop, setHomeOffsetTop] = useState();
	const [homeOffsetHeight, setHomeOffsetHeight] = useState();
	const [aboutOffsetTop, setAboutOffsetTop] = useState();
	const [aboutOffsetHeight, setAboutOffsetHeight] = useState();
	const [workOffsetTop, setWorkOffsetTop] = useState();
	const [workOffsetHeight, setWorkOffsetHeight] = useState();
	const [contactOffsetTop, setContactOffsetTop] = useState();
	const [contactOffsetHeight, setContactOffsetHeight] = useState();

	const homeSectionRef = (offsetTop, offsetHeight) => {
		// console.log("Home section offsetTop:", offsetTop);
		// console.log("Home section offsetHeight: ", offsetHeight);
		setHomeOffsetTop(offsetTop);
		setHomeOffsetHeight(offsetHeight);
	};

	const aboutSectionRef = (offsetTop, offsetHeight) => {
		// console.log("About section offsetTop: ", offsetTop);
		// console.log("About section offsetHeight", offsetHeight);
		setAboutOffsetTop(offsetTop);
		setAboutOffsetHeight(offsetHeight);
	};

	const workSectionRef = (offsetTop, offsetHeight) => {
		// console.log("work offsetTop: ", offsetTop);
		setWorkOffsetTop(offsetTop);
		setWorkOffsetHeight(offsetHeight);
	};
	const contactSectionRef = (offsetTop, offsetHeight) => {
		setContactOffsetTop(offsetTop);
		setContactOffsetHeight(offsetHeight);
	};

	return (
		<>
			<Navigation
				homeOffsetTop={homeOffsetTop}
				homeOffsetHeight={homeOffsetHeight}
				aboutOffsetTop={aboutOffsetTop}
				aboutOffsetHeight={aboutOffsetHeight}
				workOffsetTop={workOffsetTop}
				workOffsetHeight={workOffsetHeight}
				contactOffsetTop={contactOffsetTop}
				contactOffsetHeight={contactOffsetHeight}
			/>
			<HomeSection onGetRef={homeSectionRef} workOffsetTop={workOffsetTop} />
			<SeperationBar />
			<AboutSection
				onGetRef={aboutSectionRef}
				workOffsetTop={workOffsetTop}
				contactOffsetHeight={contactOffsetHeight}
			/>
			<SeperationBar />
			<WorkSection
				onGetRef={workSectionRef}
				contactOffsetTop={contactOffsetTop}
				contactOffsetHeight={contactOffsetHeight}
			/>
			<ContactSection
				onGetRef={contactSectionRef}
				workOffsetTop={workOffsetTop}
				aboutOffsetTop={aboutOffsetTop}
			/>
		</>
	);
}

export default App;
